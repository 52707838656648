<template>
  <mobile-screen
    :header="true"
    screen-class="icon-app1 reception-screen gray-bg"
  >
    <template v-slot:header>
      <top-header-menu-wrapper menu-class="icon-hea1">
        <template v-slot:left>
          <router-link :to="{ name: backLinkName }">
            <button>
              <icon icon="#cx-hea1-arrow-left" />
            </button>
          </router-link>
        </template>
        <div class="component-title">
          {{
            displayLabelName(
              "visitor-management",
              "manage-reception-desks",
              "user-container"
            )
          }}
        </div>
      </top-header-menu-wrapper>
    </template>

    <ul
      class="clebex-item-section pill"
      v-if="containersList && containersList.length"
    >
      <li class="clebex-item-section-item" :key="`none`">
        <button
          class="clebex-item-content-wrapper"
          @click="setSelectedReceptionContainer(null)"
        >
          <span class="label">
            <span class="highlight">
              {{ displayLabelName("receptions", "container", "None") }}
            </span>
          </span>
          <span class="follow-up-icons">
            <span class="follow-up-icon-item active" v-if="!receptionContainer">
              <icon icon="#cx-app1-checkmark"></icon>
            </span>
          </span>
        </button>
      </li>
      <!-- Allowed containers -->
      <li
        class="clebex-item-section-item"
        v-for="container in containersList"
        :key="container.id"
      >
        <button
          class="clebex-item-content-wrapper"
          @click="setSelectedReceptionContainer(container)"
        >
          <span class="label">
            <span class="highlight">{{ container.name }}</span>
          </span>
          <span class="follow-up-icons">
            <span
              class="follow-up-icon-item active"
              v-if="
                receptionContainer && receptionContainer.id === container.id
              "
            >
              <icon icon="#cx-app1-checkmark"></icon>
            </span>
          </span>
        </button>
      </li>
      <!-- End Allowed Containers -->
    </ul>
  </mobile-screen>
</template>

<script>
import { mapState, mapActions } from "vuex";
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
export default {
  name: "AllowedContainers",
  computed: {
    ...mapState("reception", ["allowedContainers", "receptionContainer"]),
    containersList() {
      if (this.allowedContainers && this.allowedContainers.length) {
        return this.allowedContainers;
      }
      return null;
    }
  },
  methods: {
    ...mapActions("reception", [
      "getAllowedContainers",
      "setReceptionContainer"
    ]),
    setSelectedReceptionContainer(container) {
      this.setReceptionContainer(container);
      this.$router.push({ name: this.backLinkName });
    }
  },
  components: {
    MobileScreen,
    TopHeaderMenuWrapper
  },
  props: {
    backLinkName: {
      type: String,
      required: true
    }
  }
};
</script>
